import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/hos.jpg";
import { Helmet } from "react-helmet";

const Hospital = () => {
  let altText = "Image Photo";
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EBS Software Solution -Hospital-Nursing Home Management System India
        </title>
        <meta
          name="description"
          content="Hospital Management System,Health Care Software,Hospital Billing Software Pathology Software Health information technology Patient management system Nursing home EMR (Electronic Medical Records) Nursing Billing software Clinical information system Healthcare software solutions Assisted living software "
        />
      </Helmet>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={altText}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              WardMaster
            </h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Complete solution for Hospital / Nursing Home Management system
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
              WardMaster offers- Patient Management System facilitates the
              management of OPD, IPD patient health records and data,
              appointments, medical and lab billings, prescribed medications,
              payments, ward-bed records, Nursing, OT and discharge notes.
            </p>
            <p>
              Patient Management Software helps to Manage patient appointment,
              visiting records, doctor consultation information, doctor advices,
              medicine records, prescription, treatment and billing history
              etc... It assists healthcare professionals in managing patient
              information, maintaining accurate patient records, managing
              patient’s prescriptions and their medical histories, including
              financial accounting, payment and insurance information.
            </p>
            <p
              style={{
                textAlign: "left",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              <br></br>
              Key Features of WardMaster(Hospital Management System)
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <div className="container">
              <div className="row">
                <Card>
                  <div className="item-holder">
                    <p>
                      <ol
                        style={{
                          textAlign: "justify",
                          marginRight: "5px",
                          marginBottom: "0rem",
                        }}
                      >
                        <ci>
                          WardMaster helps to Manage patient appointment,
                          visiting records, doctor consultation information,
                          doctor advices, medicine records, prescription,
                          treatment and billing history etc... The most
                          important activity in OPD is registration of patients,
                          fix appointment, prescription, vitals entry, clinical
                          history, pharmacy and pathology billing etc... Every
                          patient who comes to the hospital is registered on a
                          simple Registration Form that collects all of the
                          patient's information and gives them a unique
                          registration number. The front desk is connected to
                          all important hospital departments and serves as the
                          point of contact for patients' inquiries.
                          Additionally, the front desk handles all aspects of
                          billing.
                        </ci>
                      </ol>
                    </p>
                  </div>
                </Card>
                <div className="card">
                  <div className="item-holder">
                    <p>
                      <ol
                        style={{
                          textAlign: "justify",
                          marginRight: "5px",
                          marginBottom: "0rem",
                        }}
                      >
                        <ci>
                          WardMaster helps to Manage patient appointment,
                          visiting records, doctor consultation information,
                          doctor advices, medicine records, prescription,
                          treatment and billing history etc... All of this data
                          will be recorded and printed by the Warmaster. IPD
                          Billing Online billing for all IPD patients, including
                          patient information, daily services like room rent,
                          operations, deliveries, oxygen and other gases,
                          consultations, nursing fees, laboratory tests, X-rays,
                          ultrasounds, medications, and procedures, among other
                          things. IPD Collection Collection of partial payments,
                          full payments, refunds, and credit notes are all
                          included. The data for all MIS reports will come from
                          this information.
                        </ci>
                      </ol>
                    </p>
                    <p>
                      <ol
                        style={{
                          textAlign: "justify",
                          marginRight: "5px",
                          marginBottom: "0rem",
                        }}
                      >
                        <ci>
                          With Hospital Management Software, You'll Never Have
                          to Worry About Booking Patients, Scheduling
                          appointments, & more! WardMaster TPA Management helps
                          to Manage patient Billing, to claim insurance, and
                          manage billing on the basis of TPA sanction amount.
                          Provision to maintaining records of TPA and Insurance
                          details.
                        </ci>
                      </ol>
                    </p>
                  </div>
                </div>
                <br></br>
                <div className="card">
                  <div className="item-holder">
                    <p>
                      <ol
                        style={{
                          textAlign: "justify",
                          marginRight: "5px",
                          marginBottom: "0rem",
                        }}
                      >
                        <ci>
                          User Management : You can add, modify, and delete
                          users as well as their specific rights using the
                          Wardmaster. Moreover, in accordance with security
                          procedures. enables you to immediately revoke a user's
                          access in an emergency while maintaining their entire
                          history. Management can check operational audit
                          reports of the users.
                        </ci>
                      </ol>
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div
                    className="item-holder"
                    style={{ backgroundColor: "lightgrey" }}
                  ></div>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster offers a solution for the complete inventory
                        management cycle starting from intend to purchase order,
                        approvals and GRN with provisions for online material
                        request and issues to facilitate paper less management
                        of inventory making it a unique hospital erp system.
                        Flexible grouping of items with multiple units and
                        conversion factor combined with multiple store options
                        ensure that you have precise control on the inventory.
                        Vendor management, purchase invoice and accounts
                        payable.
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster helps to Manage patient OPD, IPD, Daycare,
                        Pharmacy, Lab, OT, Emergency and Department wise billing
                        etc... By automating tasks that would otherwise be
                        completed manually, hospital management system software
                        can help improve efficiency and free up staff time for
                        other tasks.(like discharge card, prescription, medical
                        certificate, doctors reminders, advices and patient next
                        visit reminders etc.)
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster offers a complete financial accounting
                        includes cash/bank book, receivables and payables. And
                        can generated reports upto final accounting.
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster helps to improve patient care by providing
                        access to information about patient's medical history,
                        medications and allergies. This information can help to
                        ensure that patients receive the best possible care. The
                        system is crucial for any hospital as it helps to
                        streamline the process of patient care. It allows
                        doctors and staff to keep track of appointments, medical
                        records, and prescriptions. This module also made indent
                        for medicine and also make schedule OT and various
                        services which can operate from the Nursing station
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster helps to Manage patient appointment, patient
                        treatment/advices, consultation information, doctor
                        advices, medicine records, prescription, treatment and
                        billing history etc... Maintain the medical records of
                        the patient, Maintain the contact details of the
                        patient, Keep track of the appointment dates, Save the
                        insurance information for later reference, Tracking the
                        bill payments.
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster Diagnosis/Laboratory Management Software is
                        suitable for Diagnostics Centers, clinics and Hospital
                        Labs. Diagnostics Test Rate Master, Investigation Master
                        with Reference Values, Appointments, Test wise reports,
                        Billing & Receipts, Centralized billing, Lab Report,
                        Patient Registration, Package Master and Tests summary
                        etc...
                      </ci>
                    </ol>
                  </p>
                  <p>
                    <ol
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                      <ci>
                        WardMaster offers flexible and easily configurable
                        features and facilities that makes it user-friendly and
                        accurate. It is also linked with the financial
                        accounting and other modules of WardMaster for effective
                        financial control and user access control. You won’t
                        need any complicated hardware for employess attendance.
                        WardMaster runs an Android App face recognition
                        attendance system, which provides practically 100%
                        robust and accurate attendance.
                      </ci>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
            <br></br>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Hospital;
