import React from "react";
import web5 from "../img/e1.jpg";
import h1 from "../img/h1.jpg";
import h2 from "../img/h2.jpg";
import h3 from "../img/h3.jpg";
import h4 from "../img/jwl1.jpg";
import h5 from "../img/h4.jpg";
import h6 from "../img/h5.jpg";
import h7 from "../img/h6.jpg";
import h8 from "../img/h7.jpg";
import h9 from "../img/h9.jpg";
import h10 from "../img/h10.jpg";
import h11 from "../img/h11.jpg";
import h12 from "../img/h12.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Product = () => {
  let alText = "Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/Hotelsof"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        HotelSof
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          lineHeight: "20px",
                        }}
                      >
                        Software for Hotel,Resorts ,Motels
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h1} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                              textDecoration: "none",
                            }}
                          >
                            HotelSof has been providing property management
                            systems to hotels, motels, and resorts. It is a
                            comprehensive software suite consisting of
                            integrated modules for various aspects of hotel
                            management. This class of software is often referred
                            to as Property Management System in the industry.
                            Our client base ranges from small elegant hotels to
                            large full-service properties. By developing our own
                            Room Reservations, Food & Beverage POS and complete
                            Accounting.{" "}
                            <a
                              href="/Hotelsof"
                              style={{
                                textDecoration: "none",
                                color: "rgb(118, 142, 16)",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              More Details.....
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/Hospital"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        WardMaster
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Complete Software solution for Hospital / Nursing
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h2} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            Wardmaster is powerful, flexible,easy to use and is
                            designed and developed to deliver real conceivable
                            benefits to hospitals and clinics. Hospital
                            management System in a Hospital or a Nursing Home
                            would require to very precise and must result into
                            cost cutting and efficient management. Its cover
                            Patient management(IPD and OPD), X-ray, scan and
                            Diagonestic Reports, Pharmacy, Store
                            Controlling(General and assets),PayRoll & Complete
                            accounting system. More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/teasof"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        TeaSof
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Tea Garden Software
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h3} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            TeaSof, the software which specially developed for
                            Tea Garden (Set Garden) Its cover worker wages
                            (Weekly / fortnightly / Monthly along with Kamjari
                            Analysis,periodical Garden activity),staff,
                            sub-staff salary ,Factory management, Accounting
                            (Direct connectivity with wages, salary and stock
                            controlling) , Garden activity, and also cover
                            complete inventory controlling. Software can be
                            integrated with the automation of attendance system
                            More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/jewsof"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        JewSof
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        GST enabled Jewellery Software
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h4} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            JewSof is the most trusted, tested and proven
                            software package for the jewelry industry. Its
                            advance report utility will allow you to efficiently
                            analyze your business, helping you increase your
                            sales volume and profits. When it comes to the
                            business crucial and important data, there is no
                            reason to compromise. More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/pharma"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        Pharmacist
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Software for Medicine Distributor and Retailers
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h5} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            An Integrated Accounting and Inventory Controlling
                            System for Medicine Distributor's with wide variety
                            of reports. And also its cover Batch Wise Stock
                            Status, Near Expiry. Profit analysis, slow moving
                            item statement, various analysis of sales and
                            purchase and also cover full accounting analysis .
                            Projecting sales analysis. More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/teasale"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        TeaSale
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Software for Tea Traders along with blending
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h6} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            TeaSale, the software which specially developed for
                            Tea Manufacturer Billing and Stock
                            Controlling,Blending and Auction Sales . You can
                            download one month demo version anytime. Its advance
                            report utility will allow you to efficiently analyze
                            your business, helping you increase your sales
                            volume and profits. More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/restaurant"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        RestaurSof
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        GST Enabled Software for Restaurant and Pubs
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h7} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            RestaurSof - A restaurant management software is
                            used to systematically manage restaurant operations.
                            For a dine-in restaurant this includes order taking,
                            kitchen order tickets (KOTs/BOTs), billing,
                            reporting, inventory, recipe management,GST enabled
                            reports and Financial Accounting. Web and Desktop
                            version is available. More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/bakery"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        BakeryPro
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        GST Enabled Software for Bakery Management
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h8} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            BakeryPro is integrated software for Bakery
                            Industries. His provides a multitude of features for
                            bakeries and chains to maximize their operational
                            efficiency and customer engagement! Everything from
                            order management to delivery is fully supported by
                            our system. The operations become more
                            systematically Web Version available More
                            Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/pathology"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        PathoSof
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Software for Pathology reports,Billing and Accounting
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h9} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            With minimum or zero computer knowledge the
                            receptionist can start patient registration and
                            billing from Day one. The software will
                            automatically calculate the total amount, apply the
                            discount and calculate the balance amount thus
                            avoiding any human errors.When you create a bill the
                            lab report is automatically generated with the added
                            tests.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/transport"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        TransportPro
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "25px",
                        }}
                      >
                        Complete Web Application for Transport Solution
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h10} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            Transportation is one of the biggest sector
                            now-a-days specially Goods/Courier Transportation
                            Service. Transportation indirectly affects the
                            development of a society. Get your transportation to
                            new heights with our full featured application. More
                            Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/paysof"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        PayHR
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "15px",
                        }}
                      >
                        Software for Payroll and HR
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h11} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            PayHR is the most trusted, tested and proven
                            software package for the HR Department.It is a
                            complete payroll management system with biometric
                            attendance system. Its advance report utility will
                            allow you to efficiently analyze your business,
                            helping you increase your sales volume and profits.
                            More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <Link to={"/newssof"} style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                        NewsPro
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          lineHeight: "15px",
                        }}
                      >
                        News Papers Accounting with ABC circulation
                      </p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",
                            height: "208px",
                            overflow: "hidden",
                          }}
                        >
                          <img src={h12} alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                            }}
                          >
                            An Integrated complete systems for
                            Newspapers,maintaining accounts for every-day
                            newspapers circulations outstanding
                            analysis,inventory controlling and all these
                            advertisement modules reports,market selling reports
                            and upcoming growths, ABC analysis and auditing
                            system More Details.....
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <br></br>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Product;
