/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Email from "emailjs-com";
import Swal from "sweetalert2";
const SERVICE_ID = "service_3qq5tmg";
const TEMPLATE_ID = "template_g3mrj1g";
const PUBLIC_KEY = "4EA9FyotVNbcsvPoR";
class ContactUs extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    emailStatus: "",
  };

  // handle the value
  handleCHange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  // when submit btn is clicked
  submitForm = (e) => {
    const { name, email, message } = this.state;

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step

      this.setState({
        emailStatus: xhr.responseText,
      });
    });
    // open the request with the verb and the url
    xhr.open(
      "GET",
      "http://api.ruvictor.com/sendemail/index.php?sendto=" +
        email +
        "&name=" +
        name +
        "&message=" +
        message
    );
    // send the request
    xhr.send();

    // reset the fields
    this.setState({
      name: "",
      email: "",
      message: "",
    });
    e.preventDefault();
  };

  // function sendEmail() {

  //   Email.send({
  //     Host: "smtp.ebssoftware.co.in",
  //     Username: "billing@ebssoftware.co.in",
  //     Password: "Moloy@ebs123",
  //     To: "moloy@ebssoftware.co.in",
  //     From: "billing@ebssoftware.co.in",
  //     Subject: "Sending Email using javascript",
  //     Body: "Well that was easy!!",

  //   }).then(function (message) {

  //     alert("mail sent successfully");
  //   });
  // }
  render() {
    const { name, email, mobile, address, message, emailStatus } = this.state;
    return (
      <Container>
        <Row>
          <Col lg={6} className="order-lg-first">
            <h2 className="mb-3">Contact us</h2>
            <div className="formBlock" onSubmit={this.submitForm}>
              {emailStatus ? emailStatus : null}
              <form>
                <div className="mb-3">
                  <label className="form-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    value={name}
                    onChange={this.handleCHange("name")}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    value={email}
                    onChange={this.handleCHange("email")}
                    id="email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="mobile">
                    Mobile No.
                  </label>
                  <input
                    className="form-control"
                    type="mobile"
                    id="mobile"
                    value={mobile}
                    onChange={this.handleCHange("mobile")}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="address">
                    Full Address
                  </label>
                  <input
                    className="form-control"
                    type="address"
                    id="address"
                    value={address}
                    onChange={this.handleCHange("address")}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    value={message}
                    onChange={this.handleCHange("message")}
                    required
                  />
                </div>
                <button type="submit">Send Email</button>
              </form>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div style={{ paddingTop: "110px" }}></div>

            <p style={{ fontWeight: "bold", fontSize: "30px", color: "black" }}>
              EBS Software Solution
            </p>
            <p style={{ textAlign: "justify" }}>
              EBS Software Solution,is one of the fastest growing software
              developments.The establishing objectives was to be the most
              dependable organization providing impeccable service, premium
              products and comprehensive solutions to the customers in India.
            </p>
            <ul>
              <li style={{ fontWeight: "bold" }}>Address:</li>
              <li>Udham Singh Sarani by Lane,Ashram Para Siliguri,</li>
              <li>Darjeeling, West Bengal, INDIA,Pin-734001</li>
              <li>Office : +91 97337 21111,99320 13501(Support)</li>
              <li>Sales : +91 97334 55014</li>
              <li>Email : info@ebssoftware.co.in || ebs.slg@gmail.com</li>
            </ul>

            <br></br>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ContactUs;
