import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/jbl.jpg";
import "../style/styles.css";

const JewSof = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>JewSof</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              GST enabled Jewellery outlet Billing/Inventory / Accounting
              Software
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
              JewSof is the most trusted, tested and proven software package for
              the jewelry industry. Its advance report utility will allow you to
              efficiently analyze your business, helping you increase your sales
              volume and profits. When it comes to the business crucial and
              important data, there is no reason to compromise.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Price(1 licence) : Rs. 45,000/- Multi-User+ GST
              <br></br>
              Key Features of JewSof(Jewellery Management System)
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Contractual Worker (KARIGAR)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Gold Issue to Karigar</li>
                      <li>Unfinished Ornaments Recipt from Karigar</li>
                      <li>Unfinished Ornaments issue to Goldsmith (Karigar)</li>
                      <li>
                        Finished Ornaments Receipt from Goldsmith(Karigar)
                      </li>
                      <li>Soldering Making</li>
                      <li>Old gold converts to pure gold (tezabi)</li>
                      <li>Worker (karigar) Details/Summary transaction</li>
                      <li>Pending Jobs(Order / Self) register </li>
                      <li>Worker charges details (Against jobs)</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Sales
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Customer Order</li>
                      <li>Advance against Order(Gold/Amount)</li>
                      <li>Order Cancellation</li>
                      <li>Order sends to contractual workers</li>
                      <li>Order Delivery(Gold/Silver/Diamond/Platinum etc)</li>
                      <li>Counter Sales(Gold/Silver/Diamond/Platinum etc)</li>
                      <li>Sales Return</li>
                      <li>Estimate or Quotation</li>
                      <li>Sales Register(Payment Mode Wise)</li>
                      <li>Customer order register</li>
                      <li>Pending Customer Order Register</li>
                      <li>Register of Customer's Gold and advance</li>
                      <li>Order cancellation register</li>
                      <li>GST Collection Register</li>
                      <li>Barcode / QR Code Generated</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Purchase
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Raw Gold Purchase</li>
                      <li>Finished Product Purchase</li>
                      <li>
                        Finished Product Received from contractual Workers or
                        own
                      </li>
                      <li>Purchase Return</li>
                      <li>Old gold purchase</li>
                      <li>Old gold transfer to finished Product</li>
                      <li>Pure gold purchase register</li>
                      <li>Old gold purchase register</li>
                      <li>GST ITC Reports</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Stock Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Raw Material Stock</li>
                      <li>
                        Finished Product Stock
                        Summary(Gold/Diamond/Silver/Platinium)
                      </li>
                      <li>
                        Finished Product Closing
                        Stock(Gold/Diamond/Silver/Platinium)
                      </li>
                      <li>Old gold stock register</li>
                      <li>Soldering stock register</li>
                      <li>Unfinished product stock register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Outstanding Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Debtor's Balance Summary</li>
                      <li>Aging Analysis(Debtors/Creditors)</li>
                      <li>Creditor's Balance Summary</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Cost Centre Reports</li>
                      <li>GST Type Wise Sales Register</li>
                      <li>GSTR(GSTR-I,GSTR-II,GSTR3B) Reports</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default JewSof;
