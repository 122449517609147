import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/mapp.jpg";

const Website = () => {
  let alText = "Photo";
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              Web Site and App, Android App and iOS App Development
            </h2>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <p style={{ fontWeight: "bold" }}>
              Transforming Ideas into Digital Reality
            </p>
            <p style={{ textAlign: "justify" }}>
              At EBS Software Solution, we think that the internet has the
              ability to improve online experiences and change businesses. As a
              top web development firm, we are experts at creating creative,
              user-focused, and productive digital solutions that are customized
              to match each individual client's needs.
            </p>
            <br />
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
              Our Expertise
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Custom Web Development</p>
            <p style={{ textAlign: "justify" }}>
              Take advantage of our custom web development services to start
              your digital journey. We are skilled at transforming your idea
              into a smooth and interesting online presence, whether it be for
              sophisticated corporate websites or intricate web applications.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Responsive Web Design</p>
            <p style={{ textAlign: "justify" }}>
              It is imperative to have a responsive website in the age of
              smartphone dominance. Our design team makes sure your website
              looks amazing and works perfectly on all devices, giving your
              audience the best possible user experience.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>E-Commerce Solutions</p>
            <p style={{ textAlign: "justify" }}>
              Open your digital storefront with confidence. Our e-commerce
              development services are designed to enhance your online retail
              presence, providing secure, scalable, and feature-rich solutions
              to drive sales and customer satisfaction.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              Content Management Systems (CMS)
            </p>
            <p style={{ textAlign: "justify" }}>
              Utilize our CMS solutions to regain control over your content.
              Using WordPress, Drupal, or a custom-built system, we give you the
              ability to easily update and manage your website, keeping it
              current and engaging.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Why Choose Us ?</p>
            <p style={{ textAlign: "justify" }}>
              <b>Innovative Solutions:</b> Stay ahead of the curve with our
              forward-thinking approach to web development.
            </p>
            <br />
            <p style={{ textAlign: "justify" }}>
              <b>Collaborative Process:</b> We believe in collaboration, working
              closely with you to understand your goals and vision.
            </p>
            <br />

            <p style={{ textAlign: "justify" }}>
              <b>Quality Assurance:</b> Every line of code undergoes rigorous
              testing to ensure a flawless, secure, and reliable website.
            </p>
            <br />
            <p style={{ textAlign: "justify" }}>
              <b>Dedicated Support:</b> Your success is our priority. Our
              support team is here to assist you post-launch and beyond.
            </p>
            <br />
            <br />
            <p style={{ fontWeight: "bold" }}>Apps Development</p>
            <br />
            <p style={{ fontWeight: "bold" }}>Custom Mobile App Development</p>
            <p style={{ textAlign: "justify" }}>
              Our team of talented developers is experienced in producing unique
              solutions, regardless of whether you're thinking of developing an
              app for iOS, Android, or both platforms. We create apps that not
              only meet but also surpass your expectations by combining
              usability, aesthetics, and user experience.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Cross-Platform Development</p>
            <p style={{ textAlign: "justify" }}>
              Increase your audience reach by using cross-platform development.
              Because of our proficiency with frameworks like React Native and
              Flutter, we can create apps that function flawlessly across a
              variety of platforms, cutting costs and development time without
              sacrificing quality.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>UI/UX Design</p>
            <p style={{ textAlign: "justify" }}>
              An excellent app is about more than just its features; it's about
              the user interface. Our design team creates visually appealing and
              user-friendly interfaces that increase app store visibility and
              boost user engagement.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Upkeep and Assistance for Apps</p>
            <p style={{ textAlign: "justify" }}>
              Your app's launch is only the first step. To make sure your app
              remains safe, current, and performs at its best, we provide
              continuing maintenance and support services.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              Why Choose us for App Development?
            </p>
            <p style={{ textAlign: "justify" }}>
              Innovation at the Core: We provide cutting-edge solutions that
              keep you ahead of the curve by staying up to date with the newest
              trends and technologies.
            </p>
            <br />
            <p style={{ textAlign: "justify" }}>
              <strong>Collaborative Approach</strong>: We prioritize your
              vision. We work closely with you every step of the way to make
              sure the finished product perfectly meets your needs.
            </p>
            <br />
            <p style={{ textAlign: "justify" }}>
              Quality Assurance: To ensure a bug-free, dependable, and secure
              application, each line of code is put through extensive testing.
            </p>
            <br />
            <p style={{ textAlign: "justify" }}>
              Client-Centric Support: Our committed support staff is available
              to help you at every stage of your app's lifecycle, from
              conception to launch and beyond.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>Let's Build Your App Together</p>
            <p style={{ textAlign: "justify" }}>
              EBS Software Solution is here to make your app dreams come true,
              whether you're a startup with a game-changing idea or an
              established company looking to expand your digital footprint.
              Together, let's craft something remarkable.
            </p>
            <br />
          </Col>
          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "120vh",
                width: "80vh",
                marginRight: "20px",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Website;
