import React, { useState, useEffect } from "react";
import webicon from "../img/logo.png";
import "../style/navbar.css";
import {Link, useNavigate  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";


const Navbar = () => {
  let navigate = useNavigate();
  function changeLocation(placeToGo){
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <body>
      <div
        className="d-block px-2 py-2 text-center text-bold skippy"
        style={{ backgroundColor: "red", height: 40 }}
      >
        {windowWidth <= 768 ? (
          <div
            className="mobile-view"
            style={{
              color: "white",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            GSTIN No: 19AJAPM8399K1ZE
            <a href="https://www.facebook.com/EBSSoftware/">
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  color: "white",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
            </a>
            <a href="https://twitter.com/EbsSoftware">
              <FontAwesomeIcon icon={faTwitter} style={{ color: "white" }} />
            </a>
          </div>
        ) : (
          <div className="desktop-view">
            <div style={{ float: "left" }}>
              <div>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px", color: "white" }}
                />
                <a
                  href="mailto:info@ebssoftware.co.in"
                  style={{
                    color: "white",
                    marginRight: "10px",
                    textDecoration: "none",
                  }}
                >
                  info@ebssoftware.co.in
                </a>

                <FontAwesomeIcon icon={faPhone} style={{ color: "white" }} />
                <a
                  href="tel:+91 99320 13501"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  +91 99320 13501
                </a>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <p
                style={{
                  color: "white",
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              >
                GSTIN No: 19AJAPM8399K1ZE
              </p>
              <a href="https://www.facebook.com/EBSSoftware/">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ color: "white", marginRight: "10px" }}
                />
              </a>
              <a href="https://twitter.com/EbsSoftware">
                <FontAwesomeIcon icon={faTwitter} style={{ color: "white" }} />
              </a>
            </div>
          </div>
        )}
      </div>
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
        <a href="/" className="navbar-brand d-flex align-items-center">
          <h2
            className="m-0 "
            style={{
              fontSize: "1.30rem",
              fontWeight: "bold",
              color: "black",
            }}
          >
            <img
              src={webicon}
              alt="swimmer"
              width="25px"
              style={{ float: "left", marginRight: "5px" }}
            />{" "}
            EBS Software Solution
          </h2>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
          <span class="sr-only">Toggle navigation</span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
       
          <div
            className="navbar-nav ms-auto py-4 py-lg-0"
            style={{ fontSize: "3rem" }}
          >
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              <li className="nav-item">
              <Link to="/" onClick={() => changeLocation('')} style={{ fontSize: "1rem" }} activeClassName="menu_active" className="nav-link active">Home</Link>
                {/* <NavLink
                  activeClassName="menu_active"
                  exact
                  className="nav-link active"
                  style={{ fontSize: "1rem" }}
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/Product" onClick={() => changeLocation('/Product')} style={{ fontSize: "1rem" }} className="nav-link">Products</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/Product"
                > */}
                  {/* Products */}
                {/* </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/custom" onClick={() => changeLocation('/custom')} style={{ fontSize: "1rem" }} className="nav-link">Customize Software</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/custom"
                >
                  Customize Software
                </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/website" onClick={() => changeLocation('/website')} style={{ fontSize: "1rem" }} className="nav-link">Website Development</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/website"
                >
                  Website Development
                </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/outsourcing" onClick={() => changeLocation('/outsourcing')} style={{ fontSize: "1rem" }} className="nav-link">Software Outsourcing</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/outsourcing"
                >
                  Software Outsourcing
                </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/About" onClick={() => changeLocation('/About')} style={{ fontSize: "1rem" }} className="nav-link">About us</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/About"
                >
                  About us
                </NavLink> */}
              </li>
              <li className="nav-item">
              <Link to="/EmailContactForm" onClick={() => changeLocation('/EmailContactForm')} style={{ fontSize: "1rem" }} className="nav-link">Contact us</Link>
                {/* <NavLink
                  className="nav-link"
                  style={{ fontSize: "1rem" }}
                  to="/ContactForm"
                >
                  Contact us
                </NavLink> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </body>
  );
};

export default Navbar;
