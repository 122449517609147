import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/teasale.jpg";
import "../style/styles.css";

const TeaSale = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>TeaSle</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Software for Tea Sales Solution along with blending,Billing and Inventory and accounting
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
            TeaSale, the software which specially developed for Tea Manufacturer Billing and Stock Controlling and also cover for Blending , Auction Sales and Withdrawn . You can download one month demo version anytime. Its advance report utility will allow you to efficiently analyze your business, helping you increase your sales volume and profits.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Price(1 licence) : Rs. 75,000/- Multi-User+ GST
              <br></br>
              Key Features of TeaSale(Software for Tea garden Head Office Accounting/Inventory / Billing)
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Key features(Entry Module)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                     <li>Arrival Entry(Factory Invoice)</li>
                      <li>Purchase from Outside of Garden</li>
                      <li>Inter Godwn Transfer</li>
                      <li>Blending</li>
                      <li>Delivery Order</li>
                      <li>Sales Advice</li>
                      <li>Tea Widthdran</li>
                      <li>GST General Sales Invoice</li>
                      <li>Auction and Private Auctions Billing</li>
                      <li>Export Sales</li>
                      <li>Excess</li>
                      <li>Shortage</li>
                      <li>Misc. Purchase Entry</li>
                      <li>Purchase (Service)</li>
                      <li>GST Reverse Entry</li>
                      <li>Receipt</li>
                      <li>Payment</li>
                      <li>Contra</li>
                      <li>Journal</li>
                      <li>Debit Note</li>
                      <li>Credit Note</li>
                      <li>Voucher Manage Confirmation</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    MIS Reports
                  </div>
                  <div className="item-holder">
                  <ul className="checked-list">
                  <li> Factory Invoice Register</li>
                  <li> Transit Invoice Register</li>
                  <li> Inward Stock Transfer Reports  </li> 
                  <li> Outward Stock Transfer Reports</li>
                  <li> Purchase Register (Goods and Services)</li>
                  <li> Delivery Register</li>
                  <li> Sales Advice Register</li>
                  <li> Invoice Register (General/Private GST Sale)</li>
                  <li> Export Invoice Register</li>
                  <li> Invoice (Accounts Sale) Register</li>
                  <li> Overall Sales Register</li>
                  <li> Auction Sales Register(Generated IRN by Tea Board)</li>
                  <li> Sales Return Register</li>
                  <li> Tea Widthdran Register</li>
                  <li> Inter Godwn Transfer Register</li>
                  <li> Purchase Register(Tea)</li>
                  <li> Tea Blending Register</li>
                  <li> Blend Number Wise register</li>
                  <li> LOT Query</li>
                  <li> Grade Wise Average Register</li>

                  
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Stock Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                   <li> Stock Summary(Overall) </li>
                   <li> Closing Stock(Over all) with Valuation </li>
                   <li> Stock Summary(Grade Wise) </li>
                   <li> Stock Summary(Warehouse Wise) </li>
                   <li> Stock Summary(Lot Wise) </li>
                   <li> Stock Summary(Tea Category) </li>
                   <li> Stock Summary(Arrival Wise) </li>
                   <li> Closing Stock(Grade Wise) </li>
                   <li> Closing Stock(Warehouse Wise) </li>
                   <li> Closing Stock(Lot Wise) </li>
                   <li> Closing Stock(Tea Category) </li>
                   <li> Closing Stock(Arrival Wise) </li>
                   <li> Stock Ledger</li>
                   <li> Closing stock Export to Excel for Import on Blending</li>
                   
</ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Outstanding Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Debtor's Balance Summary</li>
                      <li>Aging Analysis(Debtors/Creditors)</li>
                      <li>Creditor's Balance Summary</li>
                      <li>Aging Analysis (Bill by bill Analysis)</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Debit Note Register</li>
                      <li>Credit Note Register</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Expense Trial</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Statement of Expenditure</li>
                      <li>Cost Centre Reports</li>
                      <li>GST Type Wise Sales Register</li>
                      <li>GSTR(GSTR-I,GSTR-II,GSTR3B) Reports</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TeaSale;
