import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/bakery.jpg";
import "../style/styles.css";

const Bakery = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
            BakeryPro
            </h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              GST Enabled Web Application for Bakery Management
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p style={{ textAlign: "justify" }}>
            BakeryPro is integrated software for Bakery Industries. His provides a multitude of features for bakeries and chains to maximize their operational efficiency and customer engagement! Everything from order management to delivery is fully supported by our system. The operations become more systematically
            </p>
          
            <div className="container-container">
              <div className="row">
                <div className="card">
                <p style={{ fontWeight: "bold", color: "black" }}>
                      Key Features of BakeryPro
                    </p>
                  <p>
                   
                      BakeryPro is integrated software for Bakery Industries. His provides a multitude of features for bakeries and chains to maximize their operational efficiency and customer engagement! Everything from order management to delivery is fully supported by our system. The operations become more systematically
                     
                  </p>
                
                    <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                    Bakery POS
                    </p>
             
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}>
                 
                 Selling your baked goods on the ground has never been easier. The bakery POS software uses the latest touch-screen, scan and barcode technologies to speed up transactions wherever your customers are. Everything from order management to delivery is managed by the bakery POS module. Operations become more organized, take less time and systematically.
                   
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                  Inventory Control
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
               
               The inventory covers the management of ingredients and raw materials from reception to production and the management of intermediaries and finished products from production to transport. Inventory records are monitored and updated using numerous BatchMaster processes for their quality control facilities, weights, quantities and units of measure, as well as factory locations and licenses. Product developers see inventory costs when they add ingredients and materials to formulas and assemblies, respectively. Production staff can divide the best warehouse into batch jobs, adjust warehouse properties, and move the warehouse from one location to another or between facilities. In addition, the producers record the actual income from the batches, including the quality assurance items per batch produced. In addition, management has full visibility into all inventory changes and inventory levels based on different inventory characteristics, including a simultaneous view of weight, quantity and quantity in different units of measure.
                     
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                  Cost Effective
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
             
             Have you ever wondered how the ingredients for the Valentine Chocolate were recovered so early? This is because your customers love it and will keep coming back to find out more. And honestly, nobody likes to read "not available" written next to their preferred choice. The inventory and inventory management software on your bakery's point of sale system will help you manage all the raw materials. It will notify you immediately when you run out of ingredient. Inventory management helps you determine your food costs. Without this, your calculations are never precise.
                    
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                  Recipe Management
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                 
                 Manual baking processes are very common in small independent bakeries, especially for weighing ingredients; however, this can lead to inaccurate recipes, inconsistencies in the finished products, and even production errors. Therefore, to ensure product consistency, weight control, ingredient and recipe traceability for the baking industries, BakeryPro controlled the production raw materials that automate recipe formulation and inventory management.
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                 Accounting
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                
                      Finance and accounting software helps in regular tracking of your bakery’s sale and expenses. Stock purchase and inventory management become simplified. Better insight is gained on profitable and best-selling items, profits and much more.
BakeryPro is an integrated software where you can manage branch accounting separated and also integrated with head office accounting.
                      
                    
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                  SMS Integration
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                
                SMS Integration BakeryPro has powerful SMS integration to promote your business in the competitive business world. And also alert to customer for their order and connect in every occasion by our sms integration.
                    
                  </p>
                  <p style={{ fontWeight: "bold", color: "black",fontSize:"20px" }}>
                  Payroll
                    </p>
                  <p
                      style={{
                        textAlign: "justify",
                        marginRight: "5px",
                        marginBottom: "0rem",
                      }}
                    >
                
                In bakery Industries, there should be strong systematically attendance system. In BakeryPro, there are several way to make attendance by the staff and worker. From behind-the-counter-staff, to bakers, to janitorial staff, you must ensure that your employees are paid on time. Rather than manually preparing payroll, you can instead rely on our Payroll integrated system in BakeryPro is designed to make processing payroll for your bakery easier and faster.
                    
                  </p>
                </div>
              </div>
            </div>
            <br></br>
            {/* <p
              style={{
                textAlign: "left",
                marginRight: "5px",
                marginBottom: "0rem",
              }}
            >
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci style={{}}>Check In and Check Out Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Upgrade/Transfer Room (Check in)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Revert Check In</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Reservation</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Tourist Agent Wise Reservation and Check in Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Check In Inventory View</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Reservation View (Monthly/Date Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Type Wise Occupancy Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Type Wise Availability Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Due Report Of Current Booking (Guest Ledger)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Foreigner Certificate</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Police Report</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Room Sales Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Details Head wise Room Sales Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest History</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Company Wise Booking Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Night Audit Trial</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>In House Laundry Managing</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Telephone Register (Date and Room Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Misc. Charges Register (Date and Room Wise)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest Name Wise Booking Search</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Number Wise Booking Search</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Monthly Room Used Analysis</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Agent Wise Reservation Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Plan Wise Room Consumption (Graphical Analysis)</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Location Wise Room occupancy Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Car Rental</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Exclusive and Inclusive GST Calculation</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Room Salee GST type Wise</ci>
              </ol>
            </p>
            <p style={{ fontWeight: "bold", color: "black" }}>
              House Keeping (House Desk)
            </p>
            <p style={{ textAlign: "left", marginRight: "5px" }}>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Check In and Check Out Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci>Laundry Bill and Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Guest call Register</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Current Room Status</ci>
              </ol>
              <ol
                style={{
                  textAlign: "left",
                  marginRight: "5px",
                  marginBottom: "0rem",
                }}
              >
                <ci> Analysis of House Keeping Service</ci>
              </ol>
            </p>
          </Col>
          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "120vh",
                width: "80vh",
                marginRight: "20px",
              }}
              className="responsive-image"
              src={web5}
              alt="Right Image"
            /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Bakery;
