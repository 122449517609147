import React from "react";
import web5 from "../img/patho.jpg";
import h1 from "../img/p1.jpg";
import h2 from "../img/p2.jpg";
import h3 from "../img/p3.jpg";
import h4 from "../img/p4.jpg";
import h5 from "../img/settings.jpg";
import h6 from "../img/expr.jpg";
import { Container, Row, Col, Card } from "react-bootstrap";


const Pathology = () => {
  let alText="Photo PathoLogy";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
      <br></br>
      <Container>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <a href="/hotelsof" style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                       Patients Registration & Billing
                      </p>
                      
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "50%",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                          
                            <img src={h1}  alt={alText} />
                   
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                              textDecoration: "none",
                            }}
                          >
                          Easy patient registration allows seamless bill management and electronic records of patients, dues, discounts, and payment history centrally. Add new lab cases to existing patient record, download reports in MS-Excel, and manage rates from a single page
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <a href="/hotelsof" style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                       Lab Report Management
                      </p>
                      
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "50%",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                          
                            <img src={h2}  alt={alText} />
                          
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                              textDecoration: "none",
                            }}
                          >
                          We're standing by to answer  all queries and training support as on require 24 x 7 , after all the Quality counts on after sales support we are committed for support to our clients. The digital support our help desk is always ready to ans.
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
             
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                       Customized Test Packages
                      </p>
                      
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "50%",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                         <img src={h3}  alt={alText} />
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                              textDecoration: "none",
                            }}
                          >
                          Software gives feature of easy handling of software with customization facility, labs can prepare own letter pad logo address contact numbers in single click the feature is inbuilt in the Software. The facility to take print bills and reports on letter pad and on plane paper also.
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
            </Col>
        </Row>
        <Row>
        <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <a href="/hotelsof" style={{ textDecoration: "none" }}>
                  <div className="card">
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                       Operations & Report Activity Tracking
                      </p>
                      
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "50%",
                            height: "100px",
                            overflow: "hidden",
                          }}
                        >
                         
                            <img src={h4}  alt={alText} />
                          
                        </figure>
                      </div>
                      <div className="text-area">
                        <div className="content-text">
                          <p
                            style={{
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "justify",
                              textDecoration: "none",
                            }}
                          >
                          Easy patient registration allows seamless bill management and electronic records of patients, dues, discounts, and payment history centrally. Add new lab cases to existing patient record, download reports in MS-Excel, and manage rates from a single page
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card>
                
                  <div className="item-holder">
                    <ul className="checked-list">
                     <li>Haematology</li>
                      <li>Biochemistry</li>
                      <li>Serology & Immunology</li>
                      <li>Clinical pathology</li>
                      <li>Microbiology</li>
                      <li>Endocrinology</li>
                      <li>Histopathology</li>
                      <li>Cytology</li>
                   
                    </ul>
                  </div>
                </Card>
                </a>
              </div>
            </div>
            </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
              <a href="/pharma" style={{ textDecoration: "none" }}>
                <div className="card">
                  <div className="card-title">
                    <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                      User Management
                    </p>
                   
                  </div>
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        
                        style={{
                          width: "50%",
                          height: "100px",
                          overflow: "hidden",
                        }}
                      >
                      
                          <img src={h5}  alt={alText} />
                        
                      </figure>
                    </div>
                    <div className="text-area">
                      <div className="content-text">
                        <p
                          style={{
                            fontSize: "13px",
                            lineHeight: "20px",
                            textAlign: "justify",
                          }}
                        >
                         Software comes with exclusive Security feature. The user id & password management system with admin control facility is available in the Software. We advise admin to update with security management on regular intervals.Add more than one user access and configure permissions for each user to ensure accountability and lab data security. Provide separate permissions for lab incharge and receptionist,billing etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>
            
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
          <div className="container-container">
              <div className="row">
              <a href="/pharma" style={{ textDecoration: "none" }}>
                <div className="card">
                  <div className="card-title">
                    <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                      Reports Transfer Online
                    </p>
                   
                  </div>
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        
                        style={{
                          width: "50%",
                          height: "100px",
                          overflow: "hidden",
                        }}
                      >
                      
                          <img src={h6}  alt={alText} />
                        
                      </figure>
                    </div>
                    <div className="text-area">
                      <div className="content-text">
                        <p
                          style={{
                            fontSize: "13px",
                            lineHeight: "20px",
                            textAlign: "justify",
                          }}
                        >
                        Send reports via email, WhatsApp, and SMS, anytime, anywhere.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>
            
            </div>
          </Col>
        </Row>
           
      </Container>
        <Container fluid>
       <Row>
    
        <Col lg={12}>
         
              <div className="row">
              <a href="/pharma" style={{ textDecoration: "none" }}>
                <div className="card">
                  <div className="card-title">
                    <p style={{ fontSize: "25px", fontWeight: "bold",textAlign:"center" }}>
                      PathoSof Details Features
                    </p>
                   
                  </div>
                  <div className="item-holder">
                    <div className="image-box">
                    
                    </div>
                    <div className="text-area">
                      <div className="content-text">
                        <p
                          style={{
                            fontSize: "13px",
                            lineHeight: "20px",
                            textAlign: "justify",
                          }}
                        >
                    <div className="item-holder">
                    <ul className="checked-list" style={{color:"green"}}>
                      <Row>
                    <Col lg={4} style={{fontSize:"20px"}}>
                     <li>Patient Registration and Billing</li>
                     </Col>
                    <Col lg={4} style={{fontSize:"20px"}}>
                      <li>Sample Collection Management</li>
                      </Col>
                     <Col lg={4} style={{fontSize:"20px"}}>
                      <li>Pathology Reports Management</li>
                      </Col>
                      </Row>
                      <br></br>
                      <Row>
                    <Col lg={4} style={{fontSize:"20px"}}>
                     <li>Patient Registration and Billing</li>
                     </Col>
                    <Col lg={4} style={{fontSize:"20px"}}>
                      <li>Sample Collection Management</li>
                      </Col>
                     <Col lg={4} style={{fontSize:"20px"}}>
                      <li>Pathology Reports Management</li>
                      </Col>
                      </Row>
                   
                    </ul>
                  </div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                </a>
              </div>
            
          
          </Col>
        </Row>
       </Container>
   
      
    </>
  );
};

export default Pathology;
