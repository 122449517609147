import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/teax.jpg";
import "../style/styles.css";
import { Helmet } from "react-helmet";

const TeaSof = () => {
  let alText = "photo";
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EBS Software Solution -Tea Garden Management System,Siliguri,West
          Bengal,India
        </title>
        <meta
          name="description"
          content="Tea Garden Management Software,Tea Garden Software in Siliguri,Tea Garden software in India,Tea Garden Software in West Bengal,Plantation Management System,Tea Estate Software Solutions,Crop Monitoring Software for Tea Gardens,Harvesting Management System,Tea Plantation Inventory Software,Quality Control in Tea Production,Supply Chain Management for Tea Industry,Tea Processing Software,Tea Garden Automation Solutions,Real-time Tea Crop Tracking,Integrated Tea Plantation Software,Tea Garden Operations Management,Leaf Quality Monitoring Software,Tea Estate ERP (Enterprise Resource Planning),Tea Garden Production Analytics,Sustainable Tea Farming Software,Tea Garden Workforce Management,Tea Industry Compliance Software,Cloud-Based Tea Garden Software"
        />
      </Helmet>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>TeaSof</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Complete solution for Tea(Set Garden) Management system
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
              TeaSof, the software which specially developed for Tea Garden(Set
              Garden) Its cover worker wages,staff, sub-staff salary ,Factory
              management, Accounting, Garden activity, and also cover complete
              inventory controlling. TeaSof that helps you to manage the
              operational details of the garden. This user-friendly software
              produces useful reports and insights into the working of the
              garden.
              <strong>
                Android App Available for Worker's and employees Attendance
              </strong>
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              <br></br>
              Key Features of TeaSof(Tea Garden Management System)
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Worker's Wages
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>
                        Worker Attendance (Casual, Permanent & Contractual)
                      </li>
                      <li>Wages Sheet Wages</li>
                      <li>Summary Wage</li>
                      <li>Payment Statement (Worker's A/c No Wise)</li>
                      <li>Cash Payment Register to Others works</li>
                      <li>Wprker's Wage Slip</li>
                      <li>Daily Kamjari </li>
                      <li>Worker's Wise Kamjari </li>
                      <li>Monthly Kamjari Yearly Kamjari Abstract Kamjari</li>
                      <li>Division Wise Kamjari Register</li>
                      <li>Green Leaf and Man Power Register</li>
                      <li>Ration Register</li>
                      <li> Electric Deduction</li>
                      <li> Register LIC and others Deduction Register</li>
                      <li> Electric Non-Recovery</li>
                      <li> LIC Non-Recovery</li>
                      <li> Monthly/Quaterly PF- Statement</li>
                      <li> PF Form (3 A & 6A) </li>
                      <li> ECR Challan Generate</li>
                      <li>Absent Analysis </li>
                      <li> Arear Payment Sheet</li>
                      <li>Annual Leave Register</li>
                      <li> Bonus Statement</li>
                      <li>
                        *** Incentive & Porata Will be applied as per Tea Board
                        Rules.
                      </li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Employees Salary
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Worker Attendance(Casual, Permanent)</li>
                      <li>Attendance Check List</li>
                      <li>Pay Register(Staff/Sub-Staff)</li>
                      <li>Pay Slip</li>
                      <li>Ration Sheet</li>
                      <li>Staff & Sub-Staff PF Register</li>
                      <li>Professionals Tax Register</li>
                      <li>Annual Leave Register</li>
                      <li> Arear Pay Register</li>
                      <li> Register LIC and others Deduction Register</li>
                      <li> Bonus Register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Employees Salary
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Company-Division-Product wise Stock Status</li>
                      <li>Product wise Stock Summary</li>
                      <li>Maximum and Minimum Label Register</li>
                      <li>Slow Moving Product Stock Summary</li>
                      <li>High Value Product Stock Summary</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Purchase Analysis
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Product-Group/Company-Month Wise Purchase</li>
                      <li>Division/Sub-Group-Monthly Purchase</li>
                      <li>Purchase query</li>
                      <li>Cumulative Analysis of Purchase</li>
                      <li>Company/Group Wise Purchase-Date and Month wise</li>
                      <li>CompanyGroup Wise Purchase-(Y2K Analysis)</li>
                      <li>Product Analysis of Purchase</li>
                      <li>Supplier-Product-Division Wise Purchase Analysis</li>
                      <li>
                        Supplier-Product-Division Wise Monthly Purchase Analysis
                      </li>
                      <li>Indent Register and Print as Voucher</li>
                      <li>Purchase Order Register</li>
                      <li>Pending Purchase Order Register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Consumption Analysis
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Product-Group/Company-Month Consumption</li>
                      <li>Division/Sub-Group-Monthly Consumption</li>
                      <li>Issue/Consumption query</li>
                      <li>Division Wise Consumption</li>
                      <li>Purpose and Division Wise Consumption</li>
                      <li>Purpose Wise Consumption</li>
                      <li>SOE wise Consumption</li>
                      <li>Date wise Consumption</li>
                      <li>Department Wise Consumption</li>
                      <li>Section Wise Consumption</li>
                      <li>SOE-Purpose Wise Consumption</li>
                      <li>Product Wise Consumption</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Garden Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>SOE Ledger with cost centre Analysis</li>
                      <li>Ledger</li>
                      <li>Ledger Summry</li>
                      <li>SOE Report</li>
                      <li>Cost Centre Ledger</li>
                      <li>Accounts Group Wise Reports</li>
                      <li>Monthly Expenditure Statement</li>
                      <li>Party Wise Outstanding(Bill by Bill)</li>
                      <li>Trial Balance (Group/Noraml/Alphabatically etc)</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
        <div className="card-title">
          <p style={{ fontSize: "15px", fontWeight: "bold", color: "red" }}>
            Available Android App for worker's and employees Attendanace and
            also Weight Machine Intregrated for Plucking weighnment
          </p>
        </div>
      </Container>
    </>
  );
};

export default TeaSof;
