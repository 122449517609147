import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/news.jpg";
import web1 from "../img/ltnews.jpg";
import "../style/styles.css";
import { Helmet } from "react-helmet";

const NewsSof = () => {
  let alText = "Photo News Paper";
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EBS Software Solution -Jewellery Outlet retail Software,Siliguri,West
          Bengal,India
        </title>
        <meta
          name="description"
          content="jewellery retail software | POS software for jewellery stores | jewellery store management software | Retail jewellery inventory system |jewellery point of sale system | jewellery POS solutions | jewellery retail POS software |jewellery store POS management |Retail management system for jewellery |jewellery sales software |jewellery POS and inventory control Point of sale for jewellery | business jewellery retail technology |jewellery store POS terminal jewellery |sales and inventory software |Retail software for jewellery outlets |jewellery shop software |jewellery cashier system Cloud-based POS for jewellery stores | Jewellery Karigar Software"
        />
      </Helmet>

      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>NewsPro</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Software for Daily News Paper /Fortnightly / Monthly
              Magazine,Cover with Circulation,Advertisement,Inventory,Accounts
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
              An Integrated controlling systems for Newspapers,maintaining
              accounts for every-day newspapers circulations outstanding
              analysis,inventory controlling and all these advertisement modules
              reports,market selling reports and upcoming growths.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              <br></br>
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Key features(Entry Module)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Label Printing</li>
                      <li>Suppliment Label Printing</li>
                      <li>Road Challan</li>
                      <li>Daily Changes Reports</li>
                      <li>Sales Man Wise Daily Changes Reprots</li>
                      <li>State Wise Supply Changes Reprts</li>
                      <li>Daily Supply Report</li>
                      <li>Print Order</li>
                      <li>Suppliment-Print Order</li>
                      <li>Monthly Print Order</li>
                      <li>Non-Receipt Register</li>
                      <li>Daily Supply Summary</li>
                      <li>Monthly Supply Details</li>
                      <li>State Wise Supply Details</li>
                      <li>Sales Man Wise Supply Details/ Summary</li>
                      <li>Discount Wise Despatch Summary</li>
                      <li>
                        Agent Wise Daily/Weekly/Monthly Statement of Despatch
                      </li>
                      <li>Date Wise Monthly Supply Register</li>
                      <li>Monthly Bill Statement</li>
                      <li>OutStation Monthly Bill Summary</li>
                      <li>Credit note Register</li>
                      <li>Net Circulation</li>
                      <li>Supply Average Calculation</li>
                      <li>Agent Wise Supply Averave Register</li>
                      <li>Undristributed Register</li>
                      <li>Complementary Register</li>
                      <li>Unsold and Damage Return Register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Debit Note Register</li>
                      <li>Credit Note Register</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Expense Trial</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Statement of Expenditure</li>
                      <li>Circation Outstanding Reports</li>
                      <li>Circulation Aging Analysis</li>
                      <li>Sales Man Circulation Outstanding</li>
                      <li>State Wise Circulation Outstanding</li>
                      <li>Outstanding (NET CIRCULATION)</li>
                      <li>Sales Man Wise Outstanding (NET CIRCULATION)</li>
                      <li>State Wise Outstanding (NET CIRCULATION)</li>
                      <li>District Wise Outstanding(NET CIRCULATION)</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Stock Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Publish Date Wise Bill Register</li>
                      <li>Client & Publish Date Wise Bill Register</li>
                      <li>Agent & Publish Date Wise Bill Register</li>
                      <li>Ads. Bill Register(Display)</li>
                      <li>Ads. Bill Register(Classified)</li>
                      <li>Unbilledl Register</li>
                      <li>Classified Unbilledl Register</li>
                      <li>Cleint Wise Unbilled Register</li>
                      <li>Agent Wise Unbilled Register</li>
                      <li>Representative Wise Unbilled Register</li>
                      <li>Client Name Wise Bill Register</li>
                      <li>Agent Wise Bill Register</li>
                      <li>Sales Representative Wise Bill Register</li>
                      <li>Sector Wise Bill Register</li>
                      <li>Display-Date Wise Advertisement Reports</li>
                      <li>Display-Cleint Name Wise Query</li>
                      <li>Display-Agent Name Wise Reports</li>
                      <li>Colour Wise Report</li>
                      <li>Display-Ad Type Wise Reports</li>
                      <li>Display-Page Wise Reports</li>
                      <li>Display-Ad.Size Wise Report</li>
                      <li>Display-Representative Wise Report</li>
                      <li>Classified Date Wise Advertisement Reports</li>
                      <li>Classified Cleint Name Wise Classified Query</li>
                      <li>Classified Agent Name Wise Reports</li>
                      <li>Classified Category Wise Ads.</li>
                      <li>Classified Representative Wise Ads.</li>
                      <li>Details Ousting Reports</li>
                      <li>Cash Memo Register</li>
                      <li>Collection Register</li>
                      <li>Oustanding Summary</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    MIS Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Supplier Wise Product Purchase Register</li>
                      <li>Supplier Wise Monthly Product Purchase Report</li>
                      <li>Department Wise Store Consumption Reports</li>
                      <li>Pending Purchase Order Register</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  ></div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <img
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "60vh",
                          width: "100%",
                        }}
                        className="responsive-image"
                        src={web1}
                        alt={alText}
                      />
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewsSof;
