/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import web from "../img/hotel.jpg";
import web1 from "../img/hospital.jpg";
import web2 from "../img/teagarden.jpg";
import web3 from "../img/jwl.jpg";
import web4 from "../img/rest.jpg";
import web5 from "../img/ak1.png";
import web6 from "../img/ak2.png";
import { Container, Row, Col } from "react-bootstrap";
import "../style/styles.css";
import "../style/Footer.css";

function Home() {
  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <Carousel slide={true}>
            <Carousel.Item>
              <img
                className="d-block w-100 responsive-image "
                src={web2}
                alt="Third slide"
              />
              <Carousel.Caption
                style={{ textAlign: "left", top: 10, height: "20px" }}
              >
                <h2
                  style={{ color: "white", fontWeight: "bold", fontSize: 40 }}
                >
                  TeaSof
                </h2>
                <p className="coursolfonts" style={{ color: "#cff67c" }}>
                  TeaSof, the software which specially developed for Tea Garden,
                  Facial recognition attendance android App , Weight machine
                  integration for Plucking{" "}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 responsive-image"
                src={web}
                alt="First slide"
              />
              <Carousel.Caption style={{ textAlign: "left", top: 10 }}>
                <h2 style={{ color: "red", fontWeight: "bold", fontSize: 40 }}>
                  HotelSof
                </h2>

                <p className="coursolfonts" style={{ color: "black" }}>
                  HotelSof has been providing property management systems to
                  Hotels, Motels,Resorts and also Restaurant
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 responsive-image"
                src={web1}
                alt="Second slide"
              />

              <Carousel.Caption style={{ textAlign: "left", top: 10 }}>
                <h2 style={{ color: "red", fontWeight: "bold", fontSize: 40 }}>
                  WardMaster
                </h2>
                <p className="coursolfonts" style={{ color: "white" }}>
                  Wardmaster offers a complete, modular and active hospital
                  information system that is flexible, affordable and intuitive.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100 responsive-image"
                src={web3}
                alt="Third slide"
              />

              <Carousel.Caption style={{ textAlign: "left", top: 10 }}>
                <h2 style={{ color: "red", fontWeight: "bold", fontSize: 40 }}>
                  JEWSOF
                </h2>
                <p className="coursolfonts" style={{ color: "white" }}>
                  JewSof is the most trusted, tested and proven software package
                  for the jewelry industry.Barcode Enabled,GST Enabled Software
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 responsive-image"
                src={web4}
                alt="Third slide"
              />

              <Carousel.Caption style={{ textAlign: "left", top: 10 }}>
                <h2 style={{ color: "red", fontWeight: "bold", fontSize: 40 }}>
                  RestaurSof
                </h2>
                <p className="coursolfonts" style={{ color: "white" }}>
                  RestaurSof - A restaurant management software is used to
                  systematically manage restaurant operations.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>

      {/* Side-by-side layout on medium screens and above */}
      <marquee style={{ color: "red", fontSize: "1em", fontWeight: "bold" }}>
        Automation of Tea Software Attendance from Android App along with
        Plucking weight Machine Intregation to decrease of human efforts, GST
        Enabled Hotel and Restaurant Software,Complete Hospital Management
        system(Desktop Edition),GST enabled Jewellery Software for jewellery
        outlet from customer order to final Bill along with manufacturing
        details
      </marquee>
      <Container>
        <Row>
          <Col lg={6} className="order-lg-first">
            <br />
            <h2 style={{ fontWeight: "bold" }}>Software Development</h2>
            <br />
            <p style={{ textAlign: "justify" }}>
              Located in Siliguri, West Bengal (India),{" "}
              <b>EBS SOFTWARE SOLUTION</b> is a well-known provider of software
              solutions to customers. For any need that the modern corporate
              market has, we offer business software solutions. We have created
              practically every kind of business software to meet the demands of
              various markets. We are pleased to provide our software to the
              public and private domains. In order to assist our clients in
              finding the ideal balance between price and quality, our top-notch
              management team is always honing its methods and strategies. We
              also eagerly await the contractual portion of a business agreement
              in order to identify the "softer" concerns that have an impact on
              our clients' performance. <br />
              To ensure our clients are satisfied, we offer comprehensive
              solutions that cover installation, training, implementation,
              modification, upgrading, and other services. Our software is
              specifically designed to meet the needs of our esteemed clients.
              GST is supported in our software. It assists you in filling up
              your returns automatically according to the kind and amount of
              transactions. It is simple for a user to generate e-Way Bills,
              file GST returns, and create transactions that comply with GST
              laws. While a composite dealer can use our software to complete
              GSTR-4 reports, regular dealers can only file GSTR-1 and GSTR-3B
              returns. You can export each of these forms in Excel format.
            </p>
          </Col>

          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
                width: "100vh",
              }}
              className="responsive-image"
              src={web5}
              alt="Right Image"
            />
          </Col>
          <Col lg={6} className="order-lg-first">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
                width: "100vh",
              }}
              className="responsive-image"
              src={web6}
              alt="Right Image"
            />
          </Col>
          <Col lg={6} className="order-lg-last">
            <h3 style={{ fontWeight: "bold" }}>IT Infrastructure</h3>

            <div>
              <p style={{ textAlign: "justify", width: "100%" }}>
                With our cutting-edge software solutions, we at EBS Software
                Solutions are redefining the dynamics of IT infrastructure
                management. Uncover a new phase of productivity, safety, and
                expansion for your company.
                <br />
                Leading the way in IT infrastructure software development is EBS
                Software Solution. We are digital resilience architects, not
                just software developers. Our team of seasoned professionals is
                committed to offering strong solutions that revolutionize the
                way companies oversee their IT environments.
                <br />
                Our goal is very clear: to provide cutting-edge IT
                infrastructure software to businesses. Our goal is to be the
                engine that powers your company's smooth operations and
                steadfast security in the constantly changing digital
                environment.
                <br />
                Holistic Solutions: From network management and security to
                cloud integration and system monitoring, EBS Software Solution
                provides comprehensive IT infrastructure software.
                <br />
                Adaptive technology helps us stay ahead of the curve in a
                quickly evolving technological landscape. Because of the
                adaptability of our solutions, you can be sure that your IT
                infrastructure is ready to take on any challenge in the future.
                <br />
                Scalability and Performance: Our software grows with your
                company, regardless of size, whether you're a startup or a major
                corporation. Reach peak efficiency and dependability at every
                stage of development.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              style={{
                border: "0px solid black",
                backgroundColor: "orange",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                EBS SOFTWARE OFFERS A COMPLETE SERVICE THAT ENCOMPASSES
              </p>
            </div>
            <div className="row">
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">
                  The installation and implementation of the software.
                </p>
              </div>
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">
                  Project management of the implementation process.
                </p>
              </div>
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">
                  Training of management and staff.
                </p>
              </div>
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">
                  Site customization and software development to meet specific
                  requirements.
                </p>
              </div>
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">
                  Ongoing provision of software upgrades and support.
                </p>
              </div>
              <div className="col-md-4 indexDiv">
                <p className="text-center py-0">Scheduled training courses.</p>
              </div>
            </div>
          </Col>
          <Col lg={12}>
            <div
              style={{
                border: "0px solid black",
                backgroundColor: "orange",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                ERP IMPLEMENTATION
              </p>
            </div>
            <div
              style={{
                border: "0px solid black",
                padding: "5px 10px",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign: "center",
              }}
            >
              <p style={{ textAlign: "justify" }}>
                ERP allows businesses to dismantle traditional organizational
                silos and replace them with a horizontal structure that is
                tightly integrated and comprises closely aligned strategy,
                organizational structure, process, and technology. Applications
                for an ERP may include payroll, stock management, manufacturing,
                distribution, finance, and human resources. Our Enterprise
                Resource Planning (ERP) implementation services include
                solutions tailored to the needs of our clients, based on years
                of experience in the field. We assist businesses with the
                planning and management of ERP implementation projects so they
                can maximize benefits and reduce delays and disturbances brought
                on by the introduction of enterprise software.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div
              style={{
                border: "0px solid black",
                backgroundColor: "orange",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  marginBottom: "0px",
                  color: "black",
                }}
              >
                INNOVATIVE APPROACH
              </p>
            </div>
            <div
              style={{
                border: "0px solid black",
                padding: "5px 10px",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign: "center",
              }}
            ></div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div
              style={{
                border: "0px solid black",
                padding: "5px 10px",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign: "center",
              }}
            >
              <p style={{ textAlign: "justify" }}>
                Our innovative consultancy approach helps business, mange
                project more efficiently, reduce costs and helps to identify
                potential issues or bottlenecks upfront. Our consulting services
                cover the complete project lifecycle from inception to
                deployment, including project management, architecture, software
                development, quality assurance, training and support. They can
                also help with troubleshooting, proof of concept pilot projects,
                and evaluation of technology.
              </p>
            </div>
          </Col>
        </Row>
        <div className="row">
          <Col lg={12} className="order-lg-first">
            <div
              className="col-md-12 bgFixedIndex"
              style={{
                border: "0px solid black",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <h4 style={{ fontWeight: "bold", fontSize: "30px" }}>
                WHY CHOOSE US ?
              </h4>
              <div
                class="content-text text-justify"
                style={{ lineHeight: 2, fontSize: "17px" }}
              >
                <div class="col-md-10">
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        Innovation: Keep up to date with our cutting-edge method
                        of developing software.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        Cooperation: We value cooperation and will work closely
                        with you to comprehend your vision and objectives.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        Quality: Every line of code satisfies the highest
                        requirements for dependability and quality thanks to our
                        dedication to excellence.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        Support: Your success is our success. Our dedicated
                        support team is here to assist you at every stage of
                        your software journey.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We provide Business Software Solutions for all kinds of
                        today’s business market requirements.<br></br>
                      </ci>{" "}
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We provide complete solutions to the satisfaction of our
                        customers including installation, training,
                        implementation, modification, up-gradation etc.
                      </ci>
                    </ol>
                  </p>

                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        Our software are tailor-made to suit exactly to the
                        requirements of our valuable Customers.
                      </ci>
                    </ol>
                  </p>

                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We provide 24*7 customer support after final the
                        software.
                      </ci>
                    </ol>
                  </p>

                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>We provide domain and hosting facilities.</ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We are engaged with Website, software and web
                        application development.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        In our product we are providing flexible customization
                        according to your unique concept of organization.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We provide easy reliable and understanding product which
                        provide you to manage most of all the operations from
                        one place.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>Cost effective service and products.</ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>
                        We provide or have outsourcing facilities as well.
                      </ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>We have full customized and ready made software.</ci>
                    </ol>
                  </p>
                  <p style={{ textAlign: "left", marginRight: "5px" }}>
                    <ol>
                      <ci>Our software are GST enabled</ci>
                    </ol>
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      marginRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    <ol>
                      <ci>
                        Export data into Tally ERP/Prime from our Software
                      </ci>
                    </ol>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </Container>
  );
}

export default Home;
