import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/aboutus.jpg";

const About = () => {
  let alText = "photo";
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              About Us
            </h2>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <p style={{ textAlign: "justify" }}>
              Located in Siliguri, West Bengal (India), EBS SOFTWARE SOLUTION is
              a well-known provider of software solutions. For every need that
              the modern business market has, we offer business software
              solutions. We have created practically every kind of business
              software to meet the demands of various markets. We take great
              pride in providing our software to the public and private
              domains.To ensure our clients are satisfied, we offer
              comprehensive solutions that cover installation, training,
              implementation, modification, upgrading, and other services. Our
              software is specifically designed to meet the needs of our
              esteemed clients. In order to assist our clients in finding the
              ideal balance between price and quality, our top-notch management
              team is always honing its methods and strategies. We also eagerly
              await the contractual portion of a business agreement in order to
              identify the "softer" issues that have an impact on our clients'
              success. Maybe this explains why repeat customers account for the
              majority of our business. They believe that even though we are not
              the biggest, we are the best partner to help them achieve their
              goals. GST is supported in our software. It assists you in filling
              out your returns automatically according to the kind and amount of
              transactions. It is simple for a user to generate e-Way Bills,
              file GST returns, and create transactions that comply with GST
              laws. While a composite dealer can use our software to file GSTR-4
              returns, regular dealers can only file GSTR-1 and GSTR-3B returns.
              You can export each of these forms in Excel format.
            </p>

            <p style={{ fontWeight: "bold" }}>IT INFRASTRUCTURE SOFTWARE</p>
            <p style={{ textAlign: "justify" }}>
              IT infrastructure software is used to make sure that people and
              systems in your organization can connect to efficiently execute
              business processes, share information, and manage touch points
              with customers and suppliers. Infrastructure software dynamically
              detects relationships between resources and helps coordinate
              activities across an entire enterprise. IT infrastructure can also
              be configured to automatically alert users to relevant discoveries
              and best practices, based on their current activity and without
              requiring a user-initiated search.
            </p>
            <p style={{ textAlign: "justify" }}>
              When compared to custom-coded integration, EBS SOFTWARE SOLUTION
              integration solutions save up to 80% on costs and enable faster
              project implementations thanks to their plug-and-play design and
              openness. Additionally, they save a significant amount of
              maintenance costs because candidates can communicate with the
              standardized integration architecture of EBS Software rather than
              confusing "hard-coded" back-office system connections. EBS
              Software is the best value available today for the following
              reasons: it hides the complexity of enterprise IT environments,
              allowing resources to be mobilized to support the implementation
              of new business ideas more quickly than you ever would have
              imagined.
            </p>

            <p style={{ textAlign: "justify" }}>
              In addition, EBS Software offers a complete service that
              encompasses:
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci> The installation and implementation of the software</ci>
              </ol>{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci> Project management of the implementation process </ci>
              </ol>{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci> Training of management and staff </ci>
              </ol>{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci>
                  {" "}
                  Site customization and software development to meet specific
                  requirements{" "}
                </ci>
              </ol>{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci> Ongoing provision of software upgrades and support </ci>
              </ol>{" "}
            </p>
            <p style={{ textAlign: "justify" }}>
              <ol>
                <ci> Scheduled training courses </ci>
              </ol>{" "}
            </p>
          </Col>
          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
                width: "100vh",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
