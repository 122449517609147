import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/Outsourcing.jpg";

const Outsourcing = () => {
  let AltText = "Photo";
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              Software Outsourcing
            </h2>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <p style={{ textAlign: "justify" }}>
              In the current global economic era, outsourcing is now a crucial
              component of all organizations. Unlike in the past, outsourcing is
              now a primary business strategy for companies, giving them a
              competitive edge, rather than just a way to cut operating costs.
              It allows them to focus on their core skills while letting their
              intellectual resources contribute to the accomplishment of
              organizational goals and objectives. Software outsourcing firm EBS
              Software, situated in India, specializes in offshore software
              development that is highly efficient, timely, and reasonably
              priced.Our extensive and diverse experience in offshore software
              development, coupled with our strict quality standards and project
              management skills, guarantee that we will create solutions that
              will set your company apart from the competition. Our worldwide
              software outsourcing approach ensures that we give you the best
              possible outcome.
            </p>
          </Col>
          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100vh",
              }}
              className="responsive-image"
              src={web5}
              alt={AltText}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Outsourcing;
