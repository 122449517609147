import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/trans1.jpg";
import web1 from "../img/wlt.jpg";
import "../style/styles.css";

const Transport = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>TransportPro</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Software for Transport Management System
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
            Transportation is one of the biggest sector now-a-days specially Goods/Courier Transportation Service. Transportation indirectly affects the development of a society. Get your transportation to new heights with our full featured application.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Web application with multi branching
              <br></br>
             
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Key features(Entry Module)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                    <li>Create new Location/Place Entries of your business according to your needs.</li>
                    <li>Separate Customers on the basis of Consignor or Consignee</li>
                    <li>Generate Consignment Note Entry with Receipt Print</li>
                    <li>Generate Manifest for the consignment.</li>
                    <li>Track pending Delivery / Delivered items</li>
                    <li>Generate Money Receipt (Consignment Entry Wise)</li>
                    <li>Generate Lorry Hire Challan.</li>
                    <li>View detailed overview of generated and pending money receipt.</li>
                    <li>View detailed overview of generated and delivery status of manifest.</li>
                    <li>Generate Billing on Periodical</li>

                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                  <ul className="checked-list">
                  <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Debit Note Register</li>
                      <li>Credit Note Register</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Expense Trial</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Statement of Expenditure</li>
                      <li>Cost Centre Reports</li>
                  
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    MIS Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Supplier Wise Product Purchase Register</li>
                      <li>Supplier Wise Monthly Product Purchase Report</li>
                      <li>Department Wise Store Consumption Reports</li>
                      <li>Pending Purchase Order Register</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Reports HUB
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                   <li>Booking & Transshipment Details</li>
                   <li>Outgoing, Incoming Manifest</li>
                   <li>Run-sheet Preparation & Updating</li>
                   <li>Bookings, Dispatch and Delivery</li>
                   <li>Vendor Management for payments</li>
                   <li>Customer receipts</li>
                   <li>Provide tracking option on website</li>
                   <li>Finance & Accounts Management</li>
                   <li>Bookings & Delivery Service Location and Branch creation</li>
                   <li>Incoming, Outgoing Manifest</li>
                   <li>Receiving and booking Report</li>
                   <li>Load Handling Report</li>
                   
</ul>
                  </div>
                </Card>
     
                <Card>
                 
                  <div className="item-holder">
                  <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web1}
              alt={alText}
            />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Transport;
