/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../style/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  function changeLocation(placeToGo){
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div
            style={{
              border: "0px solid black",

              padding: "5px 10px",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "0px",
                color: "black",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14254.704323833797!2d88.4302639!3d26.7227933!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e4411204618f15%3A0xe137090f018f693c!2sEBS%20Software%20Solution!5e0!3m2!1sen!2sin!4v1687248028714!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </p>
          </div>
        </Col>
      </Row>
      <footer
        class="text-center text-white"
        style={{ backgroundColor: "rgb(241 95 95)" }}
      >
        <div class="container fluid">
          <section>
            <div class="row text-center d-flex justify-content-center pt-4">
              <div class="col-md-2">
                <h6 class="font-weight-bold">
                <Link to="/About" onClick={() => changeLocation('/About')} style={{ fontSize: "1rem" }} className="nav-link">About us</Link>
                  {/* <Link
                    to={"/About"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    About us
                  </Link> */}
                </h6>
              </div>
              <div class="col-md-2">
                <h6 class="font-weight-bold">
                <Link to="/Product" onClick={() => changeLocation('/Product')} style={{ fontSize: "1rem" }} className="nav-link">Products</Link>

                  {/* <Link
                    to={"/Product"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Product
                  </Link> */}
                </h6>
              </div>
              <div class="col-md-2">
                <h6 class="font-weight-bold">
                <Link to="/custom" onClick={() => changeLocation('/custom')} style={{ fontSize: "1rem" }} className="nav-link">Customized Software</Link>
                  {/* <Link
                    to={"/custom"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Customized Software
                  </Link> */}
                </h6>
              </div>
              <div class="col-md-2">
                <h6 class="font-weight-bold">
                <Link to="/website" onClick={() => changeLocation('/website')} style={{ fontSize: "1rem" }} className="nav-link">Web & Mobile App</Link>
                  {/* <Link
                    to={"/website"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Web & Mobile App
                  </Link> */}
                </h6>
              </div>
              <div class="col-md-2">
                <h6 class="font-weight-bold">
                <Link to="/contactus" onClick={() => changeLocation('/contactus')} style={{ fontSize: "1rem" }} className="nav-link">Contact us</Link>
                  {/* <Link
                    to={"/contactus"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Contact us
                  </Link> */}
                </h6>
              </div>
            </div>
          </section>
          <hr class="my-2" />
          <section class="mb-2">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-8">
                <p class="text-white">
                  Our world-class management team is constantly sharpening its
                  techniques and refining its approaches to help our clients
                  strike the right balance between cost and quality, and we look
                  forward to the contractual side of a business agreement to see
                  the "softer" issues that impact our clients' success.
                </p>
              </div>
            </div>
          </section>
          <section class="text-center mb-2">
            <a
              href="https://www.facebook.com/EBSSoftware/"
              class="text-white me-4"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://twitter.com/EbsSoftware" class="text-white me-4">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="" class="text-white me-4">
              <FontAwesomeIcon icon={faGoogle} />
            </a>
            <a
              href="https://www.instagram.com/ebssiliguri/"
              class="text-white me-4"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="" class="text-white me-4">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            {/* <a href="" class="text-white me-4">
              <i class="fab fa-github"></i>
            </a> */}
          </section>

          <div className="item2">
            <span style={{ paddingRight: 5 }}>Copyright </span>
            <FontAwesomeIcon icon={faCopyright} />
            <span style={{ paddingLeft: 5 }}>
              {new Date().getFullYear()} EBS Software Solution, All Rights
              Reserved.
            </span>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
