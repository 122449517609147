import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/pharma.jpg";
import "../style/styles.css";

const Pharma = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>Pharmacist</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              GST enabled Medicine Distributor and Retail Software
              Software
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
            An Integrated Accounting and Inventory Controlling System for Medicine Distributor's with wide variety of reports. And also its cover Batch Wise Stock Status, Near Expiry. Profit analysis, slow moving item statement, various analysis of sales and purchase and also cover full accounting analysis . Projecting sales analysis.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Price(1 licence) : Rs. 15,000/- Multi-User+ GST
              <br></br>
              Key Features of Pharmacist(Medicine Distributor/Dealer / Retail Software)
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Sales
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                     <li>Billing</li>
                      <li>Cash Billing</li>
                      <li>Customer-Product Wise Sales-Date and Month wise</li>
                      <li>Customer-Product Wise Sales-(Y2K Analysis)</li>
                      <li>Company-Group Wise Sales-Date and Month wise</li>
                      <li>Company-Group Wise Sales-(Y2K Analysis)</li>
                      <li>Sub-Group/Division Wise Sales-Date and Month wise</li>
                      <li>Sub-Group/Division Wise Sales-(Y2K Analysis)</li>
                      <li>Area-Customer-Product Wise Sales-Date and Month wise</li>
                      <li>Area-Customer-Product Wise Sales-(Y2K Analysis)</li>
                      <li>Product Wise Sales</li>
                      <li>Products Wise Monthly Sales</li>
                      <li>Products Wise Monthly Sales (Y2Y)</li>
                      <li>Year-to-Year Product Sales</li>
                      <li>Analysis of Non-Supplied product</li>
                      <li>Projecting Sales Analysis</li>
                      <li>Barcode / QR Code Generated</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Purchase
                  </div>
                  <div className="item-holder">
                  <ul className="checked-list">
                  <li> Product-Group/Company-Month Wise Purchase</li>
                  <li> Division/Sub-Group-Monthly Purchase</li>
                  <li> Purchase query  </li> 
                  <li> Cumulative Analysis of Purchase</li>
                  <li> Company/Group Wise Purchase-Date and Month wise</li>
                  <li> Company Group Wise Purchase-(Y2K Analysis)</li>
                  <li> Product Analysis of Purchase</li>
                  <li> Purchase-Product Analysis (Y2K Analysis)</li>
                  <li> Supplier-Product-Division Wise Purchase Analysis</li>
                  <li> Supplier-Product-Division Wise Monthly Purchase Analysis</li>
                  <li> Supplier-Product-Division Wise Purchase Analysis(Y2k)</li>
                  
                    </ul>
                  </div>
                </Card>
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Stock Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                   <li> Company-Division-Product wise Stock Status</li>
                   <li>Product wise Stock Summary</li>
                   <li>Company-Division-Product wise Closing Stock</li>
                   <li>Order Processing against Order Label</li>
                   <li>Maximum and Minimum Label Register</li>
                   <li>Damage Stock Summary</li>
                   <li>Location wise Stock Status</li>
                   <li>Graphical Analysis of Stock Status</li>
                   <li>Location Wise Stock Summary</li>
                   <li>Slow Moving Product Stock Summary</li>
                   <li>High Value Product Stock Summary</li>
                   <li>Batch Wise Stock Summary</li>
                   <li>Batch wise Stock Summary and Closing Stock</li>
                   <li>Breakage & Expiry Stock Report</li>
                   <li>Near Expiry Reports with closing stock</li>
</ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Outstanding Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Debtor's Balance Summary</li>
                      <li>Aging Analysis(Debtors/Creditors)</li>
                      <li>Creditor's Balance Summary</li>
                      <li>Aging Analysis (Bill by bill Analysis)</li>
                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                      <li>Cash Bank Book</li>
                      <li>Journal Book</li>
                      <li>Purchase Register</li>
                      <li>Purchase Return Register</li>
                      <li>Sales Register</li>
                      <li>Sales Return Register</li>
                      <li>Ledger</li>
                      <li>Trial Balance</li>
                      <li>Trading and Profit & Loss Statement</li>
                      <li>Bank Reconciliation</li>
                      <li>Interest Calculation</li>
                      <li>Confirmation of Accounts</li>
                      <li>Cost Centre Reports</li>
                      <li>GST Type Wise Sales Register</li>
                      <li>GSTR(GSTR-I,GSTR-II,GSTR3B) Reports</li>
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pharma;
