import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import web5 from "../img/hr.jpg";
import web1 from "../img/wlt.jpg";
import "../style/styles.css";

const PaySof = () => {
  let alText="Photo";
  return (
    <>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
        </Container>
        <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>PayHR</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              Software for Payroll and HR Solution
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ textAlign: "justify" }}>
            <p>
            PayHR is the most trusted, tested and proven software package for the HR Department.It is a complete payroll management system with biometric attendance system. Its advance report utility will allow you to efficiently analyze your business, helping you increase your sales volume and profits.
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "bold",
                color: "black",
              }}
            >
              Web application with multi branching
              <br></br>
             
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container">
              <div className="row">
                
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Key features(Entry Module)
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                   <li>Department Entry</li>
                   <li>Designation Entry</li>
                   <li>Employee Entry</li>
                   <li>Salary Configuration</li>
                   <li>Daily,Weekly,Monthly Shifting</li>
                   <li>Shifting Allotment</li>

                    </ul>
                  </div>
                </Card>
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Accounting Reports
                  </div>
                  <div className="item-holder">
                  <ul className="checked-list">
                  <li>Staff Attendance with finger print, card swipe & password</li>
                  <li>Daily Biometric Attendance</li>
                  <li>Facial Attendance</li>
                  <li>Employee Advance</li>
                  <li>Employee LIC ,Insurance</li>
                  <li>Leave Confirguration</li>
                  
                    </ul>
                  </div>
                </Card>
                
              </div>
            </div>

            <br></br>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container">
              <div className="row">
                <Card>
                  <div
                    className="card-title"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    Reports HUB
                  </div>
                  <div className="item-holder">
                    <ul className="checked-list">
                    <li>Employee Wise Attendance Register</li>
                    <li>Employee Wise Attendance Summary</li>
                    <li>Payslip Printing</li>
                    <li>Salary Register</li>
                    <li>PF Statement</li>
                    <li>FPF Statement</li>
                    <li>Income Tax Statement</li>
                    <li>Professional Tax Statement</li>
                   
</ul>
                  </div>
                </Card>
     
                <Card>
                 
                  <div className="item-holder">
                  <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100%",
              }}
              className="responsive-image"
              src={web1}
              alt={alText}
            />
                  </div>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaySof;
