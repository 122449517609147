import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/custom.jpg";

const Custom = () => {
  let alText = "Photo";
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>
              Customized Software Development
            </h2>
            <br />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <p style={{ textAlign: "justify" }}>
              A quality assurance software provider, EBS Software Solution
              offers custom software development utilizing appropriate software
              development methodologies. We provide software solutions that are
              advantageous to our clients. We are able to offer you a suitable
              resolution for your present and future application requirements
              and needs.We pledge to provide the best possible customer service
              by providing software development of the highest caliber.Web
              application development, customized e-commerce software, and other
              IT-enabled services. We have the newest tools and approaches for
              software development.Most of the time, standard software cannot
              meet all of your business needs. Most software bundles, even the
              best fit ones, will likely satisfy only 70% of your true needs.
              Standard software either doesn't fulfill all your needs, or the
              more expensive versions have features you'll never need.
              Therefore, why give in to something that isn't right for you? We
              offer our customers precisely the customized software solutions
              that will satisfy their unique business needs. Our group of
              skilled and knowledgeable engineers will incorporate application
              growth in your field of expertise. Our standardized software
              development process enables us to offer software solutions to
              almost any industry at a reasonable cost. This guarantees that the
              product you receive will be reliable and error-free, giving you
              the advantage over your rivals that you so richly deserve. We have
              a feedback policy in place because we recognize how important it
              is to communicate and provide client support. This guarantees that
              any inquiry or request will get a prompt response.We also focus on
              keeping you informed about the progress of your project by sending
              you scheduled reports and updates.Since it can be challenging for
              smaller businesses to afford dependable specialized software and
              programming services, we provide a professional package that is
              adaptable, economical, and can be customized to fit most budgets.
            </p>
          </Col>

          <Col lg={6} className="order-lg-last">
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "50vh",
                width: "100vh",
              }}
              className="responsive-image"
              src={web5}
              alt={alText}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Custom;
